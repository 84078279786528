.packages-based-section {
    // background: url('../../../public/svg/home/packages-based/curve-line.svg') no-repeat bottom center #FFFFFF;
    .packages-based-container {
        background-image: url('../../../public/svg/home/packages-based/camera.svg'), url('../../../public/svg/home/packages-based/aeroplane.svg'), url('../../../public/svg/home/packages-based/pointer.svg');
        background-position: right 20px bottom -20px, left 200px top, right 150px top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1279px) {
            background-position: right 20px bottom -20px, left 45px top, right 45px top;
        }
        @media only screen and (max-width: 1023px) {
            background-position: right 20px bottom -20px, left 25px top, right 25px top;
        }
        @media only screen and (max-width: 767px) {
            background-image: initial;
        }
    }
    .swiper{
        overflow: inherit;
    }
    .packages-based-slider {
        .swiper-pagination-wrapper {
            .pagination-arrows {
                figure {
                    background-color: #FFF3ED;
                    &:hover {
                        background-color: #FF4514;
                    }
                }
            }
        }
    }
    .packages-swiper {
        a {
            background-color: #fff;
            border-radius: 14px;
            width: 100%;
            figure {
                position: relative;
                border-radius: 14px;
                overflow: hidden;
                > img {
                    width: 100%;
                }
                figcaption {
                    position: absolute;
                    inset: 0;
                    opacity: 0;
                    background-color: rgba(61, 65, 82, 0.80);
                }
            }
           
            .packages-info {
                border-top: #E9DAD4 dotted 1px;
                position: relative;
                span {
                    color: #3D4152;
                }
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    background-color: #F8EAE6;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    top: -12px;
                }
                &:before {
                    left: -36px;
                }
                &:after {
                    right: -36px;
                }
            }
            &:hover {
                figure {
                    figcaption {
                        opacity: 1;
                    }
                }
                .packages-info {
                    color: #FF4514;
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        background-image: initial;
    }
}